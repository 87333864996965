import React, {useState} from 'react';
import {SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import ChecklistItem from "./ChecklistItem";
import {
    createChecklistItem,
    loadChecklistCategories,
    updateChecklistItem
} from "../../../redux-components/actions/checklistActions";
import Swal from "sweetalert2";
import {useDispatch} from "react-redux";
import ChecklistCategoryTitle from "./ChecklistCategoryTitle";
import {Toast} from "../../CustomSwal";

const ChecklistCategory = props => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false)
    const [inputItem, setInputItem] = useState("")

    const createHandler = (e) => {
        e.preventDefault();
        try {
            if(inputItem === '') {
                throw new Error('Item cannot be empty')
            }
            dispatch(createChecklistItem(props._id, inputItem));
            setInputItem("");
        } catch (e) {
            Swal.fire({
                title: "Error",
                text: e.message,
                icon: "error",
                button: "OK",
            });
        }
    }

    const uncheckAll = (e) => {
        e.preventDefault();
        try {
            props.items.forEach((item) => {
                dispatch(updateChecklistItem(item._id, item.description, false, item.position));
            })
            dispatch(loadChecklistCategories());
            Toast.fire({
                icon: 'success',
                title: 'All items unchecked'
            })
        } catch (e) {
            Toast.fire({
                icon: 'error',
                title: e.message
            })
        }
    }

    return (
        <>
            <ChecklistCategoryTitle {...props} isOpen={isOpen} onClick={props.onClick} />
            <div className={`${props.current === props?._id ? "flex flex-col" : "hidden"} space-y-2 mx-2`}>
                <form onSubmit={createHandler} className={'flex flex-row w-full'}>
                    <input onChange={(e) => setInputItem(e.target.value)} value={inputItem} type={"text"} placeholder={'New Item'} className={'text-black p-1 flex-grow rounded-l-md'} />
                    <button type={'submit'} className={'bg-green-400 hover:bg-green-700 text-white p-1 px-4 border-none'}>Add</button>
                    <button onClick={uncheckAll} type={'button'} className={'bg-orange-400 hover:bg-orange-700 text-white p-1 px-4 border-none rounded-r-md'}>Uncheck</button>
                </form>
                <SortableContext
                    items={props.items?.map(item => item?._id) || []}
                    strategy={verticalListSortingStrategy}
                >
                    {
                        props.items?.map(
                            da => <ChecklistItem {...da} key={da?._id} />
                        )
                    }
                </SortableContext>
            </div>
        </>
    );
};

export default ChecklistCategory;