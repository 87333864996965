/* eslint-disable no-unused-vars */

import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {
    checkGroceryItem,
    createGroceryFavorite, deleteGroceryFavorite,
    deleteGroceryItem,
    updateGroceryItem, updateGroceryUndoList
} from "../../redux-components/actions/groceryActions";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from '@dnd-kit/utilities'
import {Toast} from "../CustomSwal";
import {configureStore} from "@reduxjs/toolkit";
import {GROCERY_ITEM_UPDATE_ACKNOWLEDGE} from "../../redux-components/constants/groceryConstants";
import store from "../../store";
const { getState } = store;

const GroceryItem = props => {
    const dispatch = useDispatch();

    const groceryItemUpdate = useSelector((state) => state.groceryItemUpdate);
    const { success: successUpdate } = groceryItemUpdate;
    const groceryFavoritesCreate = useSelector((state) => state.groceryFavoritesCreate);
    const { success: successFavoritesCreate } = groceryFavoritesCreate;
    const groceryFavoritesDelete = useSelector((state) => state.groceryFavoritesDelete);
    const { success: successFavoritesDelete } = groceryFavoritesDelete;
    const groceryFavoritesGet = useSelector(state => state.groceryFavoritesGet);
    const { groceryFavorites } = groceryFavoritesGet;

    const [liked, setLiked] = useState(false);
    const [description, setDescription] = useState(props.description || "");

    useEffect(() => {
        if(!groceryFavoritesGet.loading) {
            if(groceryFavorites?.favorites.indexOf(props.description) > -1) {
                setLiked(true);
            } else {
                setLiked(false);
            }
        }
    }, [groceryFavorites, groceryFavoritesGet, props.description]);

    useEffect(() => {
        let debounce = setTimeout(() => {
            updateHandler();
        }, 1000);
        return () => {
            clearTimeout(debounce);
        }
    }, [description]);


    const deleteHandler = () => {
        dispatch(updateGroceryUndoList(props));

        dispatch(deleteGroceryItem(props._id))

        //console.log(`deleting id: ${props._id}`)
    }

    const checkHandler = async() => {
        try {
            await dispatch(checkGroceryItem(props._id));
        } catch (e) {
            Toast.fire({
                icon: 'error',
                title: e.message
            });
        } finally {
            dispatch({type: GROCERY_ITEM_UPDATE_ACKNOWLEDGE});
        }
    }

    const updateHandler = async() => {
        if(props.description !== description) {
            try {
                await dispatch(updateGroceryItem(props._id, description, props.position));

                const updatedState = getState().groceryItemUpdate;

                if (!updatedState.success) {
                    throw new Error('Error Updating Grocery Item');
                }

                Toast.fire({
                    icon: 'success',
                    title: 'Grocery Item updated'
                })
            } catch (e) {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                })
            } finally {
                dispatch({ type: GROCERY_ITEM_UPDATE_ACKNOWLEDGE });
            }
        }
    }

    const favoriteHandler = async() => {
        try {
            if(liked) {
                await dispatch(deleteGroceryFavorite(props.description));

                // if(!successFavoritesDelete) {
                //     throw new Error('Error removing item from favorites')
                // }

                setLiked(false)
                await Toast.fire({
                    icon: 'success',
                    title: 'Item removed from favorites'
                })
            } else {
                await dispatch(createGroceryFavorite(props.description));

                // if(!successFavoritesCreate) {
                //     throw new Error('Error adding item to favorites')
                // }

                setLiked(true)
                await Toast.fire({
                    icon: 'success',
                    title: 'Item added to favorites'
                })
            }
        } catch (e) {
            await Toast.fire({
                icon: 'error',
                title: e.message
            })
        }
    }

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({id: props._id})

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    return (
        <>
            <div ref={setNodeRef} style={style} {...attributes} {...listeners}
                 className={`flex space-x-2 touch-manipulation flex-row p-4 text-black rounded-md w-full content-center justify-center items-center ${props.isChecked ? 'bg-green-100' : 'bg-white'} active:bg-red-200`}>
                <div onClick={favoriteHandler}
                     className={'flex items-center h-8 w-8 justify-center text-red-500 cursor-pointer hover:text-red-300'}>
                    <FontAwesomeIcon icon={liked ? 'fas fa-heart' : 'far fa-heart'} size={'xl'}/>
                </div>
                <input onChange={(e) => setDescription(e.target.value)} type={'text'} value={description}
                       className={`flex flex-grow text-center bg-transparent placeholder-black content-center items-center justify-center w-full p-2 overflow-auto max-h-36 empty-scroll`}/>
                <div onClick={(e) => {
                    e.stopPropagation(); // Prevent event from bubbling up to parent
                    checkHandler();
                }}
                     className={`${props.isChecked ? "text-white" : "text-black hover:text-zinc-800"} z-50 flex items-center h-8 w-8 justify-center bg-black hover:bg-zinc-800 cursor-pointer p-2 rounded-md`}>
                    <FontAwesomeIcon icon="fas fa-check"/>
                </div>
            </div>
        </>
    );
};

export default GroceryItem;
