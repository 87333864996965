/* eslint-disable no-unused-vars */

import React, {useEffect, useRef, useState} from 'react';
import Layout from "../../components/Layout/Layout";
import {useDispatch, useSelector} from "react-redux";
import {
    createGroceryHeader,
    createGroceryItem,
    deleteAllGroceryItem, deleteCheckedGroceryItems, loadGroceryFavorites,
    loadGroceryItems, removeGroceryUndoList, updateGroceryUndoBatch, updateGroceryUndoList,
} from "../../redux-components/actions/groceryActions";
import Swal from "sweetalert2";
import {useNavigate} from "react-router";
import Loading from "../../components/Layout/Loading";
import EditButton from "../../components/Layout/EditButton";
import {customDelSwal} from "../../components/CustomSwal";
import {EDIT_MODE_FALSE} from "../../redux-components/constants/editModeConstants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GrocerySortable from "../../components/Grocery/GrocerySortable";
import GroceryFavoritesList from "../../components/Grocery/GroceryFavoritesList";
import {useSubmit} from "react-router-dom";
import store from "../../store";
import {
    GROCERY_ITEM_CREATE_ACKNOWLEDGE,
    GROCERY_ITEM_OPTIMISTIC_UPDATE
} from "../../redux-components/constants/groceryConstants";

const Grocery = () => {
    const history = useNavigate();
    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        if(!userInfo) {
            history('/login?next=/grocery');
        }
    }, [userInfo, history])

    const editMode = useSelector((state) => state.editMode);
    const groceryItemGet = useSelector(state => state.groceryItemGet);
    const { loading: groceryItemGetLoading, error: groceryItemGetError, groceryItems, length } = groceryItemGet;
    const groceryItemCreate = useSelector(state => state.groceryItemCreate);
    const { success: groceryItemCreateSuccess, error: groceryItemCreateError } = groceryItemCreate;
    const groceryUndoList = useSelector(state => state.groceryUndoList);
    const { success: groceryUndoListCreateSuccess, error: groceryUndoListCreateError, undoList } = groceryUndoList;
    const groceryItemUpdate = useSelector(state => state.groceryItemUpdate);
    const { success: groceryItemUpdateSuccess, error: groceryItemUpdateError } = groceryItemUpdate;
    const groceryItemDelete = useSelector(state => state.groceryItemDelete);
    const { success: groceryItemDeleteSuccess, error: groceryItemDeleteError, id: groceryItemDeleteId } = groceryItemDelete;
    const groceryItemDeleteAll = useSelector(state => state.groceryItemDeleteAll);
    const { success: groceryItemDeleteAllSuccess, error: groceryItemDeleteAllError } = groceryItemDeleteAll;
    const groceryItemDeleteChecked = useSelector(state => state.groceryItemDeleteChecked);
    const { success: groceryItemDeleteCheckedSuccess, error: groceryItemDeleteCheckedError } = groceryItemDeleteChecked;

    const groceryFavoritesGet = useSelector(state => state.groceryFavoritesGet);
    const { loading: groceryFavoritesGetLoading, error: groceryFavoritesGetError, groceryFavorites, length: favoritesLength } = groceryFavoritesGet;
    const groceryFavoritesCreate = useSelector((state) => state.groceryFavoritesCreate);
    const { success: successFavoritesCreate } = groceryFavoritesCreate;
    const groceryFavoritesDelete = useSelector((state) => state.groceryFavoritesDelete);
    const { success: successFavoritesDelete } = groceryFavoritesDelete;

    const [edit, setEdit] = useState(false);
    const [item, setItem] = useState("");
    const [header, setHeader] = useState("");
    // eslint-disable-next-line
    const [state, setState] = useState([]);
    const [undoListState, setUndoListState] = useState([]);
    const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);

    useEffect(() => {
        if (groceryItemDeleteCheckedSuccess) {
            dispatch(loadGroceryItems());
        }
    }, [dispatch, groceryItemDeleteCheckedSuccess]);

    useEffect(() => {
        dispatch(loadGroceryItems());
        dispatch(loadGroceryFavorites());

        // Set up visibility change listener
        const handleVisibilityChange = () => {
            if (!document.hidden) {
                dispatch(loadGroceryItems());
                dispatch(loadGroceryFavorites());
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        // Clean up the event listener
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [dispatch]);

    useEffect(() => {
        setUndoListState(undoList);
    }, [undoList]);

    useEffect(() => {
        setState(groceryItems);
    }, [groceryItems])

    useEffect(() => {
        async function fetchFavorites() {
            await dispatch(loadGroceryFavorites());
        }
        fetchFavorites();
    }, [dispatch, groceryItems, successFavoritesCreate, successFavoritesDelete]);

    useEffect(() => {
        async function fetchGrocery() {
            await dispatch(loadGroceryItems());
        }
        fetchGrocery();
    }, [dispatch, userInfo, groceryItemDeleteAllSuccess]);

    useEffect(() => {
        setEdit(editMode);
    }, [editMode])

    const updateAutocompleteSuggestions = (input) => {
        if(input.length === 0) {
            setAutocompleteSuggestions([]);
            return;
        }

        const filteredSuggestions = groceryFavorites?.favorites.filter(favorite =>
            favorite.toLowerCase().startsWith(input.toLowerCase())
        );
        setAutocompleteSuggestions(filteredSuggestions);
    }

    const handleSuggestionSelect = (suggestion) => {
        setItem(suggestion);
        setAutocompleteSuggestions([]);
        // Use setTimeout to ensure this runs after state updates
        setTimeout(() => {
            createHandler(null, suggestion);
        }, 0);
    };

    const createHandler = async(e, suggestedItem = null) => {
        if(e) e.preventDefault();
        try {
            const itemToAdd = suggestedItem || item;
            if(itemToAdd === '') {
                throw new Error('Item cannot be empty')
            }
            dispatch(createGroceryItem(itemToAdd));
            setItem("");
            setAutocompleteSuggestions([]);
        } catch (e) {
            Swal.fire({
                title: "Error",
                text: e.message,
                icon: "error",
                button: "OK",
            });
        }
    };

    const createHeaderHandler = async(e) => {
        e.preventDefault();
        try {
            if(header === '') {
                throw new Error('Item cannot be empty')
            }
            dispatch(createGroceryHeader(header));
            setHeader("");
        } catch (e) {
            Swal.fire({
                title: "Error",
                text: e.message,
                icon: "error",
                button: "OK",
            });
        }
    }

    const deleteAllItems = async() => {
        customDelSwal.fire({
            title: `Are you sure you want to delete all items?`,
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
            denyButtonText: 'Delete all items',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        }).then((result) => {
            if(result.isDenied) {
                //delete all reminders
                dispatch(deleteAllGroceryItem());
                dispatch({type: EDIT_MODE_FALSE});
            } else if(result.isConfirmed) {

            }
        })
    }

    const deleteCheckedItems = async() => {
        customDelSwal.fire({
            title: `Delete all checked items?`,
            text: "This will remove all items you've marked as checked",
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
            denyButtonText: 'Delete checked items',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        }).then((result) => {
            if(result.isDenied) {
                // Before deleting, refresh the grocery items to get the latest server state
                dispatch(loadGroceryItems()).then(() => {
                    // Use store's getState() method
                    const state = store.getState();
                    const updatedGroceryItems = state.groceryItemGet.groceryItems;

                    if (updatedGroceryItems && updatedGroceryItems.length > 0) {
                        const checkedItems = updatedGroceryItems.filter(item => item.isChecked);
                        if (checkedItems.length > 0) {
                            dispatch(updateGroceryUndoBatch(checkedItems));
                        }
                    }

                    dispatch(deleteCheckedGroceryItems());
                    dispatch({type: EDIT_MODE_FALSE});
                });
            }
        })
    }

    const refreshHandler = async() => {
        dispatch(loadGroceryItems());
    }

    function undoRecent() {
        if(undoListState.length > 0) {
            const last = undoListState.length-1;
            const lastItem = undoListState[last];

            if (lastItem.type === 'batch') {
                // Restore all items in the batch
                lastItem.items.forEach(item => {
                    dispatch(createGroceryItem(item.description));
                });
            } else {
                // Restore single item
                dispatch(createGroceryItem(lastItem.description));
            }

            // Remove from undo list
            dispatch(removeGroceryUndoList());

            // Silently refresh the list after a short delay to ensure server sync
            setTimeout(() => {
                dispatch(loadGroceryItems());
            }, 500);
        }
    }

    return (
        <>
        <Layout title={"Grocery List"} col>
            <div className={'flex flex-col w-full md:w-2/3 h-full mx-auto'}>
                <div className={'flex flex-col w-full space-y-2 mb-2'}>
                    {/* Mobile only: buttons row (visible on small screens, hidden on md and up) */}
                    <div className="flex md:hidden flex-row justify-between gap-2 w-full">
                        <div className="flex flex-row gap-2">
                            <button
                                title={'Refresh List'}
                                onClick={refreshHandler}
                                type={'button'}
                                className={'bg-white border-black border-2 text-black p-2 rounded-md hover:bg-zinc-300'}>
                                <FontAwesomeIcon icon="fas fa-refresh"/> <span className="ml-1">Refresh</span>
                            </button>
                            <button
                                title={'Undo Action'}
                                onClick={undoRecent}
                                type={'button'}
                                className={`${undoListState?.length > 0 ? "bg-white" : "bg-zinc-500"} border-black border-2 text-black p-2 rounded-md hover:bg-zinc-300`}>
                                <FontAwesomeIcon icon="fas fa-undo"/> <span className="ml-1">Undo</span>
                            </button>
                        </div>

                        {edit && (
                            <div className="flex flex-row gap-2">
                                <button
                                    onClick={deleteAllItems}
                                    type={'button'}
                                    title="Delete All Items"
                                    className={'bg-red-500 text-white p-2 border-black border-2 rounded-md hover:bg-red-300'}>
                                    <FontAwesomeIcon icon="fas fa-trash"/> Delete All
                                </button>
                                <button
                                    onClick={deleteCheckedItems}
                                    type={'button'}
                                    title="Delete Checked Items"
                                    className={'bg-red-500 text-white p-2 border-black border-2 rounded-md hover:bg-red-300'}>
                                    <FontAwesomeIcon icon="fas fa-check"/> Delete Checked
                                </button>
                            </div>
                        )}
                    </div>

                    {/* Desktop layout (original form) - hidden on mobile, visible on md and up */}
                    <form onSubmit={createHandler} className={'hidden md:flex flex-row w-full gap-2'}>
                        <button
                            title={'Refresh List'}
                            onClick={refreshHandler}
                            type={'button'}
                            className={'bg-white border-black border-2 text-black p-2 rounded-md hover:bg-zinc-300 w-10'}>
                            <FontAwesomeIcon icon="fas fa-refresh"/>
                        </button>
                        <button
                            title={'Undo Action'}
                            onClick={undoRecent}
                            type={'button'}
                            className={`${undoListState?.length > 0 ? "bg-white" : "bg-zinc-500"} border-black border-2 text-black p-2 rounded-md hover:bg-zinc-300 w-10`}>
                            <FontAwesomeIcon icon="fas fa-undo"/>
                        </button>
                        <div className="relative flex flex-grow">
                            <input
                                onChange={(e) => {
                                    setItem(e.target.value);
                                    updateAutocompleteSuggestions(e.target.value);
                                }}
                                onBlur={() => setTimeout(() => setAutocompleteSuggestions([]), 100)}
                                value={item}
                                type="text"
                                className="bg-white border-black border-2 text-black p-2 text-md w-full rounded-md"
                            />
                            {autocompleteSuggestions?.length > 0 && (
                                <ul className="absolute z-50 w-full bg-white border-2 border-black mt-12 rounded-md shadow-lg">
                                    {autocompleteSuggestions.map((suggestion, index) => (
                                        <li
                                            key={index}
                                            className={`px-4 py-2 hover:bg-zinc-400 cursor-pointer ${index !== 0 ? "border-t-2 border-black" : ""}`}
                                            onMouseDown={() => {
                                                handleSuggestionSelect(suggestion);
                                            }}
                                        >
                                            {suggestion}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <button type={'submit'}
                                className={'bg-white border-black border-2 text-black p-2 rounded-md hover:bg-zinc-300'}>Add
                            Item
                        </button>
                        {
                            edit ? (
                                <>
                                    <button
                                        onClick={deleteAllItems}
                                        type={'button'}
                                        title="Delete All Items"
                                        className={'bg-red-500 text-white p-2 border-black border-2 rounded-md hover:bg-red-300 w-10 flex items-center justify-center'}>
                                        <FontAwesomeIcon icon="fas fa-trash-alt"/>
                                    </button>
                                    <button
                                        onClick={deleteCheckedItems}
                                        type={'button'}
                                        title="Delete Checked Items"
                                        className={'bg-red-500 text-white p-2 border-black border-2 rounded-md hover:bg-red-300 w-10 flex items-center justify-center'}>
                                        <FontAwesomeIcon icon="fas fa-check-square"/>
                                    </button>
                                </>
                            ) : ""
                        }
                    </form>

                    {/* Mobile layout for input and add button - visible on mobile, hidden on md and up */}
                    <form onSubmit={createHandler} className={'flex md:hidden flex-row w-full gap-2'}>
                        <div className="relative flex flex-grow">
                            <input
                                onChange={(e) => {
                                    setItem(e.target.value);
                                    updateAutocompleteSuggestions(e.target.value);
                                }}
                                onBlur={() => setTimeout(() => setAutocompleteSuggestions([]), 100)}
                                value={item}
                                type="text"
                                className="bg-white border-black border-2 text-black p-2 text-md w-full rounded-md"
                                placeholder="Enter item..."
                            />
                            {autocompleteSuggestions?.length > 0 && (
                                <ul className="absolute z-50 w-full bg-white border-2 border-black mt-12 rounded-md shadow-lg">
                                    {autocompleteSuggestions.map((suggestion, index) => (
                                        <li
                                            key={index}
                                            className={`px-4 py-2 hover:bg-zinc-400 cursor-pointer ${index !== 0 ? "border-t-2 border-black" : ""}`}
                                            onMouseDown={() => {
                                                handleSuggestionSelect(suggestion);
                                            }}
                                        >
                                            {suggestion}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <button type={'submit'}
                                className={'bg-white border-black border-2 text-black p-2 rounded-md hover:bg-zinc-300 whitespace-nowrap'}>Add
                            Item
                        </button>
                    </form>

                    {/* Header form - same for all screen sizes */}
                    {edit && (
                        <form onSubmit={createHeaderHandler}
                              className={'flex flex-row content-center items-center justify-center gap-2 w-full mx-auto'}>
                            <input
                                onChange={(e) => setHeader(e.target.value)}
                                value={header}
                                type={'text'}
                                placeholder="Enter header..."
                                className={'bg-white border-black border-2 text-black p-2 text-md flex-grow rounded-md'}/>
                            <button
                                type={'submit'}
                                className={'border-black border-2 bg-white text-black p-2 rounded-md hover:bg-zinc-300 whitespace-nowrap'}>
                                Add Header
                            </button>
                        </form>
                    )}

                    <GroceryFavoritesList/>
                </div>


                <div className={`space-y-2 flex flex-col relative content-center items-center justify-center w-full`}>
                    {groceryItemGetLoading && (
                        <div
                            className="absolute inset-0 flex items-center justify-center z-10 bg-black bg-opacity-30 rounded-lg">
                            <Loading rounded={"rounded-lg"} opacity={"bg-opacity-80"}/>
                        </div>
                    )}
                    <GrocerySortable/>
                </div>
            </div>
        </Layout>
            <div className='block fixed right-5 bottom-5 z-50 text-white'>
                <EditButton/>
            </div>
        </>
    );
};

export default Grocery;
